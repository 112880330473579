import 'vue-select/dist/vue-select.css';
import axios from "axios"
import { component as VueNumber } from '@coders-tm/vue-number-format'
import { directive as VNumber } from '@coders-tm/vue-number-format'
import { ref } from 'vue';
import {useToast} from 'vue-toast-notification';
import vSelect from 'vue-select'
import 'vue-toast-notification/dist/theme-bootstrap.css';
import "admin-lte"
import "bootstrap"
import "jquery"
import { computed } from 'vue';

const $toast = useToast();
const backend_data=window.backend_data


export const program_manager_app={
    mounted() {
        this.get_programs()
    },
    data() {
        return {
            prueba:"HOLAS",
            role:backend_data.current_user.role,
            filters:{
                f0:null,
                f1:null,
                current_view:"active",
                admin_view:"all",
                consultant_id:null,
                _id:null,
                docs:null,
                pdt:null,
                diag:null,
                program:null,
                search:null
            },
            programs:[],
            spinners:{main:false},
            consultants:backend_data.consultants,
            editing_program:null,
            selected_consultant:null        }
    },
    methods: {
        select_view:function(view){
            this.filters.current_view=view;
            this.get_programs()
        },
        clearSearch: function(){
            this.filters.search = null;
            this.get_programs();
        },
        select_admin_view:function(view){
            this.filters.admin_view=view
        },
        get_programs:function(){
            this.spinners.main=true
            axios.get("/programs.json",{params:this.getparams}).then(res=>{
                this.programs=res.data.programs
                this.spinners.main=false
            })     
        },
        modal_consultant:function(pid){
            this.editing_program=pid
            $("#modal_consultant").modal("show")
        },
        assign_consultant:function(){
            axios.patch("/programs/"+this.editing_program+".json",{authenticity_token:backend_data.authenticity_token,program:{consultant_id:this.selected_consultant}}).then(r=>{
                this.selected_consultant=null
                $("#modal_consultant").modal("hide")
                this.get_programs()
            }).catch(err=>{
                console.log("ERROR",err);
                $("#modal_consultant").modal("hide")
                $toast.error("Error: "+err.response.data.msg,{position:"top-right",duration:5000});
            })

        },
        showprogram:function(pid){
            window.location.href = "/programs/"+pid;
        }
    },
    computed: {
        getparams() {
            let uri = window.location.search.substring(1);
            const params = new URLSearchParams(uri);
            params.append("review",true)
            for (let f in this.filters){
                if (this.filters[f]!=null) params.append(f,this.filters[f])
            }
            return params;
        }
    },
        components: {
        'v-select': vSelect,
        'vue-number': VueNumber
    },
}